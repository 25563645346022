import { Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageAds,
  getHomepageLogo,
  getTrendingChip,
} from "../../../redux/actions/job_action";
import HomepageAnimatedHeader from "./HomepageAnimatedHeader/HomepageAnimatedHeader";
import HomepageDropResume from "./HomepageDropResume/HomepageDropResume";
import HomepageLogo from "./HomepageLogo/HomepageLogo";
import HomepageMobileLogo from "./HomepageMobileLogo/HomepageMobileLogo";
import HomepageSearchBar from "./HomepageSearchBar/HomepageSearchBar";
import {
  BlankContainer,
  DesktopContainer,
  LogoContainer,
  MobileContainer,
  MobileLogoContainer,
  OuterContainer,
  SearchContainer,
} from "./styles";

export default function HomepageSearch(props) {
  const { userResume, setUserResume, inputFileRef } = props;

  const dispatch = useDispatch();
  const homepageLogo = useSelector((state) => state?.jobs?.homepageLogo);
  const alertBanner = useSelector((state) => state.dialog.showAlertBanner);

  // const homepageLogo = [];

  const trendingChip = useSelector((state) => state?.jobs?.trendingChip);

  useEffect(() => {
    dispatch(getHomepageLogo());
    dispatch(getTrendingChip());
    dispatch(getHomepageAds());
  }, []);

  // Pulsing sequence

  const screenDesktop = useMediaQuery("(min-width:1280px)");
  const screenTablet = useMediaQuery("(min-width:768px)");

  const imagePlaceholder = [
    "/images/homepage-logo-placeholder/icon_internship.webp",
    "/images/homepage-logo-placeholder/icon_court.webp",
    "/images/homepage-logo-placeholder/icon_shop.webp",
    "/images/homepage-logo-placeholder/icon_puzzle.webp",
    "/images/homepage-logo-placeholder/icon_hiredly_logo.webp",
    "/images/homepage-logo-placeholder/icon_code.webp",
    "/images/homepage-logo-placeholder/icon_fire.webp",
    "/images/homepage-logo-placeholder/icon_company.webp",
    "/images/homepage-logo-placeholder/icon_medical.webp",
  ];

  return (
    <Fragment>
      {screenDesktop ? (
        // For desktop view
        <OuterContainer
          container
          justifyContent="center"
          alignItems="center"
          $alertBanner={alertBanner}
        >
          <Fade in={true} timeout={400}>
            <DesktopContainer container direction="row">
              {/* For custom cursor when hovering onto company logo */}
              <SearchContainer
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                alignContent="flex-start"
                direction="column"
                item
                xs={5.5}
                lg={5.5}
              >
                <Grid>
                  <Grid>
                    <HomepageAnimatedHeader />
                    <HomepageSearchBar />
                  </Grid>
                  <HomepageDropResume
                    inputFileRef={inputFileRef}
                    triggerToast={props.triggerToast}
                    userResume={userResume}
                    setUserResume={setUserResume}
                  />
                </Grid>
                {/* <HomepageTrending /> */}
              </SearchContainer>

              <LogoContainer
                container
                item
                xs={6.5}
                lg={6.5}
                direction="row"
                columnGap="40px"
              >
                {/* Search Bar */}
                <HomepageLogo imageList={homepageLogo} />
              </LogoContainer>
            </DesktopContainer>
          </Fade>
        </OuterContainer>
      ) : (
        // For mobile view
        <Fade in={true} timeout={400}>
          <MobileContainer
            container
            direction="column"
            sx={{
              minHeight:
                Array.isArray(homepageLogo) &&
                homepageLogo.length < 1 &&
                "100% !important",

              paddingTop:
                Array.isArray(homepageLogo) &&
                homepageLogo.length < 1 &&
                "50px !important",
            }}
          >
            {screenTablet ? (
              <LogoContainer
                container
                item
                direction="row"
                columnGap="4%"
                padding="3%"
                xs={12}
              >
                <HomepageLogo
                  imageList={homepageLogo}
                  imagePlaceholder={imagePlaceholder}
                />
              </LogoContainer>
            ) : (
              <BlankContainer container>
                <MobileLogoContainer
                  margin="auto"
                  justifyContent="center"
                  alignItems="center"
                  display={"grid"}
                  columnGap="12%"
                  rowGap="12%"
                  gridTemplateColumns="repeat(3, auto)"
                  xs={12}
                >
                  {Array.from({ length: 9 }).map((_, index) => {
                    return (
                      <HomepageMobileLogo
                        key={index}
                        id={index}
                        imageList={homepageLogo[index]}
                        imagePlaceholder={imagePlaceholder}
                      />
                    );
                  })}
                </MobileLogoContainer>
              </BlankContainer>
            )}

            <SearchContainer container item lg={5.5} minHeight={"fit-content"}>
              <Grid height={"100%"}>
                <Grid>
                  <HomepageAnimatedHeader />
                  <HomepageSearchBar />
                </Grid>
                <HomepageDropResume
                  inputFileRef={inputFileRef}
                  triggerToast={props.triggerToast}
                  userResume={userResume}
                  setUserResume={setUserResume}
                />
              </Grid>
              {/* <HomepageTrending /> */}
            </SearchContainer>
          </MobileContainer>
        </Fade>
      )}
    </Fragment>
  );
}
