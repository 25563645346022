import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

// Image Component //
export const ImageStyled = styling("img")({
  width: "5rem",
  height: "5rem",
  borderRadius: "0.625rem",
  objectFit: "contain",
});

// Grid Components //
export const OuterContainer = styling(Grid)((props) => ({
  width: "100%",
  height: "auto",
  backgroundColor: Color.homepageGrey,
  padding: "60px 100px",
  marginTop: props.$alertBanner ? "64px" : "0px",
}));

export const BlankContainer = styling(Grid)({
  marginBottom: "20px",
});

export const DesktopContainer = styling(Grid)(({ theme }) => ({
  width: "100%",
  height: "490px",
  backgroundColor: Color.homepageGrey,
  maxWidth: "1340px",
  margin: "auto",
}));

export const MobileContainer = styling(Grid)({
  width: "100%",
  height: "100%",
  minHeight: "650px",

  marginBottom: "30px",

  backgroundColor: Color.homepageGrey,
});

export const SearchContainer = styling(Grid)((props) => ({
  height: "100%",
  minHeight: "200px",
  justifyContent: "center",

  [props.theme.breakpoints.down("tablet")]: {
    marginTop: "0px",
  },
}));

export const LogoContainer = styling(Grid)((props) => ({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
}));

export const MobileLogoContainer = styling(Grid)({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px 40px 70px 40px",
  minHeight: "400px",
});

export const DesktopImageContainer = styling(Grid)({
  display: "flex",
  height: "100%",
  width: "auto",
  justifyContent: "center",
  alignItems: "center",
});

export const ImageContainer = styling(Grid)((props) => ({
  backgroundColor: Color.white,
  transitionDuration: "0.5s",
  transform: props.transform,
  height: "100px",
  width: "100px",
  opacity: props.opacity,
  borderRadius: "20px",
  zIndex: 1,
  right: props.right,

  ":hover": {
    cursor: "none",
  },

  [props.theme.breakpoints.down("lg")]: {
    transitionDuration: "2.5s",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: "80px !important",
    width: "80px !important",
  },
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "20px",
    padding: "5px",
    border: "3px solid black",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 5,
    cursor: "none",
  },
});

// Typography Component //
export const CursorTypography = styling(Typography)({
  fontSize: "12px",
});
