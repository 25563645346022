import AWS from "aws-sdk";

const uploadFileToS3 = async (file) => {
  // S3 Bucket Name
  const S3_BUCKET = "assets.wobbjobs.resume.com";

  // S3 Region
  const REGION = "ap-southeast-1";

  // S3 Credentials
  AWS.config.update({
    accessKeyId: process.env.NEXT_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.NEXT_S3_SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const formatFileName = file?.name.replace(/\s+/g, "_");
  const extension = formatFileName?.split(".").pop(); // Get file extension
  const baseFileName = formatFileName?.replace(`.${extension}`, ""); // Remove extension

  const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  const fileNameWithTimestamp = `${timestamp}_${baseFileName}.${extension}`; // Timestamp first

  // Files Parameters
  const params = {
    Bucket: S3_BUCKET,
    Key: fileNameWithTimestamp,
    Body: file,
  };

  try {
    // Uploading file to S3
    const upload = await s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        //
      })
      .promise();

    // File successfully uploaded, generate file URL
    const fileURL = `https://s3.ap-southeast-1.amazonaws.com/${S3_BUCKET}/${fileNameWithTimestamp}`;
    // Return the file URL
    return fileURL;
  } catch (err) {
    console.error("Error uploading file:", err);
    return null; // Return null if the upload fails
  }
};

export { uploadFileToS3 };
